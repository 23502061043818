import * as React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import { NextSeo } from 'next-seo';
import Script from 'next/script';
import { useRouter } from 'next/router';
import Layout from '../../components/general/Layout';
import Header from '../../components/general/Header';
import { CANONICAL_URL, ENDPOINT } from '../../api/constants';
import GameApp from '../../components/games/index';

export default function Home({ data }) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { category } = router.query;

  return (
    <>
      <NextSeo
        title={`${category} | Online Casino India - Trusted, Secure and Legal | Bleon77`}
        description='Bleon77 Online Casino India is the trusted, secure and legal online casino with a variety of games from slot machines to roulette and poker.'
        canonical={`${CANONICAL_URL}/slots`}
      />
      <Layout selectedIcon={t('common.profile')}>
        <Header>
          <GameApp data={data} />
        </Header>
      </Layout>

      {
        category === 'instant-games'
        && (
          <Script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: `
          {
            "@context": "http://schema.org",
            "@type": "Games",
            "name": "Instant Games",
            "description": "Instant Games",
            "url": "https://bleon77.com/instant-games"
            }
          `,
            }}
          />
        )
      }
      <Script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@context": "http://schema.org",
            "@type": "Casino",
            "name": "Live Casino",
            "description": "Live Casino",
            "url": "https://bleon77.com/live-casino"
          }
          `,
        }}
      />
    </>
  );
}

export async function getServerSideProps(context) {
  const { query, locale } = context;

  const options = {
    method: 'GET',
    url: `${ENDPOINT}/branches`,
    params: { _id: process.env.BRANCH_ID },
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios.request(options);
  const branchData = res?.data?.data[0] || {};

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      data: {
        query,
        branchId: process.env.BRANCH_ID,
        ...branchData,
      },
    },
  };
}
